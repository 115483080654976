import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ButtonGroup, Button } from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer (props) {
  const { file } = props;
  const [pageCount, setPageCount] = useState(0);
  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess (document) {
    const { numPages } = document;
    setPageCount(numPages);
  }

  function onError (error) {
    console.log(error);
  }

  function onZoomIn () {
    setScale(scale + 0.5);
  }

  function onZoomOut () {
    setScale(scale - 0.5);
  }

  let container = document.getElementsByClassName('image-gallery-swipe');
  if (!container.length) {
    container = document.getElementsByClassName('image-gallery-slide');
  }
  const pageWidth = container.length ? container[0].clientWidth : undefined;

  return (
    <Document className={`pdf-wrapper ${scale > 1 ? 'zoomed' : ''}`} file={file}
              onLoadSuccess={onDocumentLoadSuccess} onSourceError={onError}
              onLoadError={onError}
              onRenderError={onError}>
      {Array.from(
        new Array(pageCount),
        (el, index) => (
          <div key={`page_${index + 1}`} className="pb-4">
            <Page pageNumber={index + 1} scale={scale} width={pageWidth}/>
          </div>
        ),
      )}
      <ButtonGroup className="position-absolute" size="sm" style={{ bottom: 0, right: 0 }}>
        <Button variant="dark" disabled={scale === 1} onClick={onZoomOut}>-</Button>
        <Button variant="dark" onClick={onZoomIn}>+</Button>
        <Button variant="dark" as="a" target="_blank" href={file}
                download>Download</Button>
      </ButtonGroup>
    </Document>
  );
}

PDFViewer.propTypes = {};
PDFViewer.defaultProps = {};

export default PDFViewer;
