import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaultImageUrl from '../../../assets/img/pattern.png';
import './POIPrintPreview.scss';

const POIPrintPreview = React.forwardRef((props, ref) => {

    const { iconType, title, subTitle, image, fields, details } = props;

    return (
      <div ref={ref} className="POIPrintPreview">
        <section>
          <div className="card border-0 rounded-0 bg-white cursor-default">
            <div className="card-image-container img-contain">
              {image}
              <div
                className="card-img-overlay d-flex align-items-end p-3 bg-primary-transparent col m-auto">
                <div className="card-title text-white row m-0">
                  <div className="bg-primary rounded icon-container text-center">
                    <i className={`ic icon text-white ic-${iconType}`}/>
                  </div>
                  <div className="col">
                    <h4>{title}</h4>
                    <p className="card-text mb-0">{subTitle}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container px-5 py-3">
            <div className="card-text mb-3">
              {fields}
            </div>
            <div className="card-text">
              {details}
            </div>
          </div>
        </section>
      </div>
    );
})

POIPrintPreview.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconType: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fields: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};
POIPrintPreview.defaultProps = {
  image: defaultImageUrl,
  title: '',
  details: '',
  subTitle: '',
  fields: ''
};

export default POIPrintPreview;
