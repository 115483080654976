import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Pagination (props) {
  const {t} = useTranslation();

  const { pageCount, currentPage, onPageChange } = props;
  return (
    <>
      {currentPage > 1 ? <button type="button" onClick={() => onPageChange(currentPage - 1)}
                               className="btn btn-link"><i
        className="icon icon-arrow_left small"/></button> : null}
      <div className="pagination-content"><strong
        className="text-primary">Page {currentPage}</strong> {t('results:pagination')} {pageCount}
      </div>
      {currentPage !== pageCount ? <button type="button" onClick={() => onPageChange(currentPage + 1)}
                                             className="btn btn-link"><i
        className="icon icon-arrow_right small"/>
      </button> : null}
    </>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
Pagination.defaultProps = {};

export default Pagination;
