import React from 'react';
import PropTypes from 'prop-types';
import './PageIndicators.scss';

function PageIndicators (props) {
  const { count, activeIndex, onSelect } = props;

  if (count <= 1) {
    return null;
  }

  const items = [];
  for (let i = 0; i < count; i++) {
    items.push(<li key={`page-${i}`} className={`list-inline-item ${activeIndex === i ? 'active' : ''} ${onSelect ? 'cursor-pointer' : 'cursor-default'}`}
                   onClick={onSelect && ((e) => onSelect(i, e))}/>);
  }
  return (
    <ul className="PageIndicators list-inline d-flex justify-content-center align-items-center mb-1">
      {items}
    </ul>
  );
}

PageIndicators.propTypes = {
  count: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func
};
PageIndicators.defaultProps = {
  count: 0,
  activeIndex: 0,
};

export default PageIndicators;
