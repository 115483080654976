import React, { useContext, useEffect, useState } from "react";
import "react-dates/initialize";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Range, getTrackBackground } from "react-range";
import { AppContext } from "../../../../context";
import { useTranslation } from "react-i18next";

const RangeSliderFilter = (props) => {
  const { t } = useTranslation();
  const { name, onSelect, setShow, range, selectedValues } = props;
  const isMobile =
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1;
  const [values, setValues] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const { layout, projectConfigs } = useContext(AppContext);

  useEffect(() => {
    if (range) {
      if (selectedValues && selectedValues[name]) {
        setValues([selectedValues[name][0], +selectedValues[name][1]]);
        onSelect(name, selectedValues[name][0], selectedValues[name][1]);
      } else {
        setValues([+range.min_date - 1, +range.max_date + 1]);
      }

      setMinValue(+range.min_date - 1);
      setMaxValue(+range.max_date + 1);
    }
  }, []);

  const handleAccept = () => {
    setShow(false);
    onSelect(name, values[0], values[1]);
  };

  const handleRangeChange = (values) => {
    setValues(values);
  };

  const resetDate = () => {
    setShow(false);
    setValues([minValue, maxValue]);
    onSelect(name);
  };

  const dateItem = (
    <Dropdown.Item>
      <div>
        <p className="mb-0">min - {values[0]}</p>
        <p className="mb-0">max - {values[1]}</p>
        {/* m² */}
      </div>
      <div className="range-min-max">
        <Range
          values={values}
          min={minValue}
          max={maxValue}
          onChange={handleRangeChange}
          renderTrack={({ props, children }) => (
            <div
              className="line"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{ ...props.style }}
            >
              <div
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: values,
                    colors: [
                      projectConfigs.secondaryColor,
                      projectConfigs.primaryColor,
                      projectConfigs.secondaryColor,
                    ],
                    min: minValue,
                    max: maxValue,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, index, isDragged }) => (
            <div className="points" {...props} style={{ ...props.style }} />
          )}
        />
      </div>
      <div className="apply-clear">
        <div onClick={handleAccept}>{t(`search.filters.apply`)}</div>
        <div onClick={resetDate}>{t(`search.filters.clear`)}</div>
      </div>
    </Dropdown.Item>
  );
  const dropdownMenuContents = (
    <Dropdown.Menu
      as="ul"
      className={
        "list-group  date-range number-range-filter " +
        (layout === 2 && !isMobile ? "dropdown-menu-right" : "")
      }
      flip={props.flipDropdown}
    >
      {dateItem}
    </Dropdown.Menu>
  );

  if (props.containerId) {
    let container = document.getElementById(props.containerId);
    if (!container) {
      container = document.createElement("div");
      container.id = props.containerId;
      container.style.position = "absolute";
      container.style.left = 0;
      container.style.top = 0;
      container.style.zIndex = "9999";
      container.style.transform = "translate3D(0px,32px,0px)";
      document.body.appendChild(container);
    }

    return ReactDOM.createPortal(dropdownMenuContents, container);
  } else {
    return dropdownMenuContents;
  }
};
export default RangeSliderFilter;
