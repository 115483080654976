import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import MapPage from "./MapPage";
import SearchPage from "./SearchPage";
import { AppContext } from "../../../context";
import { useParams } from "react-router-dom";
import ListPage from "./ListPage";
import MapLoader from "../../../components/loaders/MapLoader/MapLoader";
import POIPage from "./POIPage";
import AppInfoModal from "../../../components/modals/AppInfoModal/AppInfoModal";
import { getDomain } from "../../../utils/envUtils";

function Pages({ project, loading, basemaps, ...props }) {
  const { defaultMobilePage } = project.configs;
  const location = useLocation();
  let { page, lang } = useParams();
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [openAppInfoModal, setAppInfoModal] = useState(false);

  const handleModalClose = () => {
    setAppInfoModal(false);
  };

  useEffect(() => {
    location.state && location.state.prevPath
      ? setIsFirstLoad(false)
      : setIsFirstLoad(true);
  }, [location]);

  useEffect(() => {
    if (project) {
      const d = getDomain();
      const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

      if (
        !!project.configs.appInfoModalEnabled &&
        project.configs.appInfoModal[lang] && project.configs.appInfoModal[lang].length > 0 &&
        project.configs?.appInfoModalEmergence === "onEveryLoad"
      ) {
        setAppInfoModal(true);
      }

      if (isFirstLaunch) {
        localStorage.setItem(`${d}-mpr-visited`, "1");
        if (
          !!project.configs.appInfoModalEnabled &&
          (project.configs?.appInfoModalEmergence === "onFirstLoad" ||
            !project.configs?.appInfoModalEmergence)
        ) {
          setAppInfoModal(true);
        }
      }
    }
  }, [project]);



  if (loading) {
    return (
      <div
        className={`container-fluid sidebar h-100 w-100 px-0 position-absolute page-active}`}
      >
        <MapLoader />
      </div>
    );
  }

  if (page) {
    return (
      <AppContext.Consumer>
        {({ layout }) => {
          return (
            <>
              <MapPage
                projectConfigs={project.configs}
                basemaps={basemaps}
                loading={loading}
                position={
                  layout === "sidebarLeft" || layout === "headerLeft"
                    ? "right"
                    : layout === "sidebarRight" || layout === "headerRight"
                    ? "left"
                    : "full"
                }
                active={page === "map" || (page === "poi" && isFirstLoad)}
                {...props}
              />
              <ListPage active={page === "list"} {...props} />
              <SearchPage active={page === "search"} {...props} />
              <POIPage
                active={page === "poi" && !isFirstLoad}
                {...props}
                // selection={dsWithSelection}
              />
              {props.showModal && openAppInfoModal && (
                <AppInfoModal
                  open={openAppInfoModal}
                  onClose={handleModalClose}
                  project={project}
                  appInfoModal={project.configs.appInfoModal}
                />
              )}
            </>
          );
        }}
      </AppContext.Consumer>
    );
  }

  return (
    <Redirect
      to={{
        pathname: `/${lang}/${defaultMobilePage}`,
        hash: window.location.hash,
      }}
      from="/:lang"
      exact
    />
  );
}

Pages.propTypes = {};
Pages.defaultProps = {};

export default Pages;
