import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import './Tutorial.scss'
import {useTranslation} from "react-i18next";
import {Layers} from '@mappr/react-lib';

const Tutorial = (props) => {
    const [position, setPosition] = useState('left');
    const [arrowClass, setArrowClass] = useState('float-left')
    const [getArrow, setGetArrow] = useState(<span>&#8592;</span>)
    const {t} = useTranslation();
    console.log(props)
    useEffect(() => {
        if (props.layout === 'sidebarLeft' || props.layout === 'headerLeft') {
            setPosition('right')
        }
        if (props.layout === 'sidebarRight') {
            setArrowClass('float-right')
            setGetArrow(<span>&#8594;</span>)
        }
        if (props.layout === 'headerLeft' || props.layout === 'headerRight') {
            setArrowClass('')
            setGetArrow(<span>&#8593;</span>)
        }
    }, []);

    return (
        <Container fluid className='mappr-tutorial' onClick={() => props.handleOpenTutorial(false)}>
            <Row>
                <Col md={8} className={`${position === 'left' ? 'order-1' : 'order-2'}`}>
                    <Row>
                        <div className={`col-8 ${position === 'left' ? 'order-1' : "order-2"}`}>
                            <div className={`map-zoom ${position === 'left' ? 'float-left' : "float-right"}`}>
                                <div className='text text-center'>
                                    <p>{t('tutorial.zoom-line-1')}</p>
                                    <p>{t('tutorial.zoom-line-2')}</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`col-4 ${position === 'left' ? 'order-2 text-right' : "order-1 text-left"}`}>
                            <div
                                className={`text d-inline-block  ${(props.layout === 'headerLeft' || props.layout === 'headerRight') ? 'filter-header' : 'filter-left-right'}`}>
                                <span
                                    className={`layer-arrow ${arrowClass}`}>{getArrow}</span>
                                <p className='text-center mb-0 d-inline-block ml-2'>{t('tutorial.filter')}</p>
                            </div>
                        </div>
                    </Row>
                    {/*{mapConfig.showLayers &&*/}
                    <Row>
                        <div className='col-8 order-1 tour-layer  ml-4'>
                            <div className='text d-inline-block'>
                                <p className='text-center mb-0'>{t('tutorial.secondary-layers')}</p>
                                <span className='layer-arrow'>&#8601;</span>
                            </div>
                        </div>
                    </Row>
                    {/*}*/}
                    <div className='map-place'>
                        <div className='circle'/>
                        <div className='text text-center'>
                            <p>{t('tutorial.map-line-1')}</p>
                            <p>{t('tutorial.map-line-2')}</p>
                        </div>
                    </div>
                    <Layers group={"streetView"} type="multi">
                        {({layers, activeLayers}, {toggleLayer}) => {
                            if (!layers.length) {
                                return null;
                            } else {
                                return <Row>
                                    <div className='col-8 order-1 street-view-bottom ml-4'>
                                        <div className='text d-inline-block '>
                                            <span className='layer-arrow float-left mt-3'>&#8592;</span>
                                            <p className='text-center mb-0 d-inline-block ml-2'>{t('tutorial.street-view')}</p>
                                        </div>
                                    </div>
                                </Row>
                            }
                        }}
                    </Layers>
                </Col>
                <Col md={4}
                     className={`px-0 poi-tour-text text ${position === 'left' ? 'order-2' : 'order-1'}`}>
                    <p className=' text-center mb-0 d-inline-block ml-2 mt-5'>{t('tutorial.pois')}</p>
                </Col>
            </Row>

        </Container>
    )

}
export default Tutorial;
