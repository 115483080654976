import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context";

export default function POIFields(props) {
  const { t } = useTranslation();
  const { projectConfigs } = props;

  let poiFields = props.poiFields || [];

  if (!poiFields.length) {
    return null;
  }

  const _getFormattedFieldValue = (poiFieldOptions, value) => {
    if (!value) {
      return poiFieldOptions.default || "";
    }

    let newValue = value;

    if (value.constructor === Array) {
      if (!value.length) {
        return null;
      }
      newValue = [...value];
    }
    // in case we need to add a suffix
    if (poiFieldOptions.suffix) {
      if (newValue.constructor === Array) {
        if (poiFieldOptions.spreadSuffix) {
          newValue = newValue.map(
            (v) => v + t(`project:${poiFieldOptions.suffix}`)
          );
        } else {
          newValue.push(t(`project:${poiFieldOptions.suffix}`));
        }
      } else {
        newValue = newValue + t(`project:${poiFieldOptions.suffix}`);
      }
    }

    //in case we need to add a prefix
    if (poiFieldOptions.prefix) {
      if (newValue.constructor === Array) {
        if (poiFieldOptions.spreadPrefix) {
          newValue = newValue.map(
            (v) => t(`project:${poiFieldOptions.prefix}`) + v
          );
        } else {
          newValue.unshift(t(`project:${poiFieldOptions.prefix}`));
        }
      } else {
        newValue = t(`project:${poiFieldOptions.prefix}`) + newValue;
      }
    }

    if (newValue.constructor === Array) {
      const separator = poiFieldOptions.separator || " ";
      newValue = newValue.join(separator);
    }

    return newValue;
  };

  poiFields = poiFields[props.index];
  return poiFields.map((poiField) => {
    let value = props.item[poiField.field];

    const renderProps = {
      key: poiField.field,
      id: poiField.field,
      label: poiField.label,
      value: _getFormattedFieldValue(poiField, value),
      icon: poiField.icon,
      inline: projectConfigs.poiFieldsView === "line",
    };

    return props.renderItem(renderProps);
  });
}

POIFields.propTypes = {
  poiFields: PropTypes.array,
  index: PropTypes.number,
  item: PropTypes.object.isRequired,
  renderItem: PropTypes.func.isRequired,
  projectConfigs: PropTypes.object.isRequired
};

POIFields.defaultProps = {
  index: 0,
};
