import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { SelectedItem } from "@mappr/react-lib";
import POIHeader from "./POIHeader";
import { AppContext, MapContext } from "../../../context";
import POIContent from "./POIContent";
import POIGalleries from "./POIGalleries";
import { useAnalytics } from "@mappr/react-lib";

function POIDetails({ fixedHeader, printable, style, history }) {
  const [fullScreen, setFullScreen] = useState(false);
  const printPreviewRef = useRef();

  const analytics = useAnalytics();

  function handleDeselect(onDeselect, setMapItem) {
    onDeselect();
    setMapItem && setMapItem("selected", null, {});

    analytics.setDimentions({ dimension3: "" });
    analytics.sendPageView();

    setFullScreen(false);
    if (history) {
      history.goBack();
    }
  }

  function onLoadCallback(item) {
    if (item) {
      const itemName = item.title || item.guid; //TODO: get title prop name from project configs
      analytics.setDimentions({ dimension3: itemName });
      analytics.sendPageView();
      analytics.sendEvent({
        category: "POI",
        action: "select",
        label: itemName,
      });
    }
  }

  return (
    <SelectedItem onLoad={onLoadCallback}>
      {({ loading, error, item }, { deselect }) => {
        if (!item) {
          return null;
        }

        if (error) {
          return <div className="notification is-danger">{error}</div>;
        }

        const contents = (
          <>
            <MapContext.Consumer>
              {({ feature: { setMapItem } }) => (
                <POIHeader
                  isFullscreen={fullScreen}
                  toggleFullscreen={setFullScreen}
                  deselected={() => handleDeselect(deselect, setMapItem)}
                  history={history}
                  fixed={fixedHeader}
                  detachedHeader={fixedHeader}
                  deselectBtnType={
                    !fixedHeader && !fullScreen ? "cross" : "back"
                  }
                  printPreviewRef={printPreviewRef}
                  printable={printable}
                />
              )}
            </MapContext.Consumer>
            <AppContext.Consumer>
              {({ projectConfigs, domain }) => (
                <div
                  className={`${
                    !fixedHeader ? "overflow-y-auto" : ""
                  } container-fluid px-0`}
                >
                  <POIContent
                    poi={item}
                    isFullscreen={fullScreen}
                    printPreviewRef={printPreviewRef}
                    projectConfigs={projectConfigs}
                  />
                  <POIGalleries
                    domain={domain}
                    mainImage={item.properties.mediasets.main}
                    images={item.properties.mediasets.gallery || []}
                    medias={item.properties.mediasets.medias || []}
                    pdfs={item.properties.pdfs}
                    inColumn={fullScreen}
                    includeMainImage={projectConfigs.includeMainImageInGallery}
                    includeMediaThumbnails={
                      projectConfigs.includeMediaThumbnailsInGallery
                    }
                  />
                </div>
              )}
            </AppContext.Consumer>
          </>
        );

        return fixedHeader ? (
          contents
        ) : (
          <div
            style={style}
            className={`POIPage bg-white ${
              fullScreen
                ? "position-fixed fullscreen"
                : "position-absolute"
            }`}
          >
            {contents}
          </div>
        );
      }}
    </SelectedItem>
  );
}

POIDetails.propTypes = {
  fixedHeader: PropTypes.bool,
  printable: PropTypes.bool,
  style: PropTypes.object,
};

POIDetails.defaultProps = {
  fixedHeader: false,
  printable: false,
};

export default POIDetails;
