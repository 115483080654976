import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../context";
import Header from "./Header/Header";
import Tutorial from "./Tutorial/Tutorial";
import AppInfoModal from "../../components/modals/AppInfoModal/AppInfoModal";
import ConfigModal from "../../components/modals/ConfigModal/ConfigModal";
import Sidebar from "./Sidebar/Sidebar";
import Map from "./Map/Map";
import ShareModal from "../../components/modals/ShareModal/ShareModal";
import GalleryModal from "../../components/modals/GalleryModal/GalleryModal";
import StreetViewModal from "../../components/modals/StreetViewModal/StreetViewModal";
import "./DesktopLayout.scss";
import {getDomain} from "../../utils/envUtils";
import {getHash, setHash} from "@mappr/react-lib/lib/utils/urlUtils";
import {useLocation} from "react-router-dom";

const mapControlsContainers = {
    "sidebarRight": [
        "mapboxgl-ctrl-bottom-right",
        "mapboxgl-ctrl-top-right"
    ],
    "headerRight": [
        "mapboxgl-ctrl-bottom-right",
        "mapboxgl-ctrl-top-right"
    ],
    "sidebarLeft": [
        "mapboxgl-ctrl-bottom-left",
        "mapboxgl-ctrl-top-left"
    ],
    "headerLeft": [
        "mapboxgl-ctrl-bottom-left",
        "mapboxgl-ctrl-top-left"
    ],
}

const reducer = (state, update) => {
    switch (update.prop) {
        case "sidebarStyleTransform":
            return {
                ...state,
                sidebarStyle: {
                    ...state.sidebarStyle,
                    transform: update.value,
                },
            };
        case "sidebarStyle":
            return {
                ...state,
                sidebarStyle: {
                    ...state.sidebarStyle,
                    paddingTop: update.value,
                },
            };
        case "poiPageStyle":
            return {
                ...state,
                poiPageStyle: {
                    ...state.poiPageStyle,
                    paddingTop: update.value,
                },
            };
        case "poiContainerStyle":
            return {
                ...state,
                poiContainerStyle: {
                    top: update.value,
                },
            };
        case "mapLegendStyle":
            return {
                ...state,
                mapLegendStyle: {
                    paddingTop: update.value,
                },
            };

        default:
            throw new Error();
    }
};

function DesktopLayout({ loading, logout, project, basemaps, draw}) {
  const { t } = useTranslation();
  const sidebarRef = useRef(null);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [openTutorial, setOpenTutorial] = useState(false);


  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const hideModal = searchParams.get('hideModal') || null;
  const [openAppInfoModal, setAppInfoModal] = useState(hideModal === null);


  const {locale, layout, streetViewModal} = useContext(AppContext);
  const [sidebarOpen, setSidebarOpen] = useState(
      getHash("sidebarOpen") !== "false"
  );
  const [lastSidebarWidth, setLastSidebarWidth] = useState("")

    const [customStyles, setCustomStyles] = useReducer(reducer, {
        sidebarStyle: {
            paddingTop: layout.includes("header") ? "73px" : "10px",
        },
        poiPageStyle: {
            paddingTop: layout.includes("header") ? "63px" : "0px",
            height: '100%'
            // height: 'calc(100% - 40px)'
        },
        poiContainerStyle: {
            top: "calc(66px + 1.5rem)",
        },
        mapLegendStyle: {
            paddingTop: layout.includes("header") ? "73px" : "10px",
        },
        poiContainerOpen: false,
        openTutorial: false,
        isFirstPage: true,
    });

    useEffect(() => {
        if (project) {
            const d = getDomain();
            const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

      if (
        !!project.configs.appInfoModalEnabled &&
        project.configs.appInfoModal[locale.value] && project.configs.appInfoModal[locale.value].length > 0 &&
        project.configs?.appInfoModalEmergence === "onEveryLoad"
      ) {
        setAppInfoModal(true);
      }

            if (isFirstLaunch) {
                localStorage.setItem(`${d}-mpr-visited`, "1");
                setOpenTutorial(
                    !project.configs.appInfoModalEnabled &&
                    !!project.configs.tutorialEnabledOnFirstLoad
                );

                if (
                    !!project.configs.appInfoModalEnabled &&
                    (project.configs?.appInfoModalEmergence === "onFirstLoad" ||
                        !project.configs?.appInfoModalEmergence)
                ) {
                    setAppInfoModal(true);
                }
            } else {
                setOpenTutorial(false);
            }
        }
    }, [project]);

    useEffect(() => {
        if (streetViewModal.open) {
            setSidebarOpen(false);
        }
    }, [streetViewModal]);

    useEffect(() => {
        if (!sidebarRef.current || !project.configs?.sidebarCollapse) return
        const sidebarTransform = `translateX(${sidebarOpen ? "none" : ["sidebarLeft", "headerLeft"].includes(layout) ? `-${sidebarRef.current.offsetWidth}px` : `${sidebarRef.current.offsetWidth}px`})`
        const controlsTransform = `translateX(${!sidebarOpen ? "0" : ["sidebarLeft", "headerLeft"].includes(layout) ? `${sidebarRef.current.offsetWidth}px` : `-${sidebarRef.current.offsetWidth}px`})`

        setCustomStyles({prop: "sidebarStyleTransform", value: sidebarTransform});

        mapControlsContainers[layout].forEach(_ => {
            const mapMenuContainer = document.getElementsByClassName(_)[0];
            if (mapMenuContainer) {
                mapMenuContainer.style.transition = "all 200ms ease-in-out"
                mapMenuContainer.style.transform = controlsTransform
            }
        })
    }, [sidebarRef.current, window.innerWidth, sidebarOpen])


    const toggleSidebar = () => {
        console.log('--sidebarOpen--',sidebarOpen)
        if (sidebarOpen) setLastSidebarWidth(sidebarRef.current.offsetWidth)

        const sidebarTransform = `translateX(${!sidebarOpen ? "0" : ["sidebarLeft", "headerLeft"].includes(layout) ? `-${sidebarRef.current.offsetWidth}px` : `${sidebarRef.current.offsetWidth}px`})`
        const controlsTransform = `translateX(${sidebarOpen ? "0" : ["sidebarLeft", "headerLeft"].includes(layout) ? `${lastSidebarWidth}px` : `-${lastSidebarWidth}px`})`
        setCustomStyles({prop: "sidebarStyleTransform", value: sidebarTransform});


        mapControlsContainers[layout].forEach(_ => {
            const mapMenuContainer = document.getElementsByClassName(_)[0];
            if (mapMenuContainer) {
                mapMenuContainer.style.transform = controlsTransform
            }
        })
        if (streetViewModal.open) {
            streetViewModal.handleClose();
        }
        setHash("sidebarOpen", "" + !sidebarOpen);
        setSidebarOpen((prevState) => !prevState);
    };

    const handleOpenTutorial = (isOpen) => {
        setOpenTutorial(isOpen);
    };
    const handleModalClose = () => {
        setAppInfoModal(false);
    };
    if (loading) {
        return t("common:loading");
    }

    const handleCloseStreetView = () => {
        toggleSidebar()
        streetViewModal.handleClose();
    }

    const handleSubheaderToggle = (opened, height) => {
        let sidebarPaddingTop = "1rem",
            poiContainerTop = "calc(66px + 1.5rem)",
            poiTop = "0",
            mapLegendTop = "0";
        if (opened) {
            sidebarPaddingTop = `calc(${height}px + 1rem)`;
            poiContainerTop = `calc(${height}px + 66px + 1.5rem)`;
            poiTop = `63px`;
            mapLegendTop = height;
        }
        setCustomStyles({prop: "sidebarStyle", value: sidebarPaddingTop});
        setCustomStyles({prop: "poiPageStyle", value: poiTop});
        setCustomStyles({prop: "poiContainerStyle", value: poiContainerTop});
        setCustomStyles({prop: "mapLegendStyle", value: mapLegendTop});
    };

    return (
        <AppContext.Consumer>
            {({
                  locale,
                  layout,
                  shareModal,
                  configModal,
                  galleryModal,
                  streetViewModal,
              }) => {
                return (
                    <>
                        <Header
                            projectConfigs={project.configs}
                            isFirstPage={isFirstPage}
                            handleOpenTutorial={handleOpenTutorial}
                            handleSubheaderToggle={handleSubheaderToggle}
                        />
                        <div
                            className="container-fluid h-100"
                            style={
                                project.configs.showHeader
                                    ? {
                                        paddingTop:
                                            layout === "headerLeft" || layout === "headerRight"
                                                ? "66px"
                                                : "60px",
                                    }
                                    : {}
                            }
                        >
                            <section
                                className={`row justify-content-center h-100 overflow-hidden  ${project?.configs?.sidebarCollapse ? 'position-relative' : ''}`}>
                                <Map
                                    projectConfigs={project.configs}
                                    basemaps={basemaps}
                                    loading={loading}
                                    draw={draw}
                                    mapLegendStyle={customStyles.mapLegendStyle}
                                    position={
                                        layout === "sidebarLeft" || layout === "headerLeft"
                                            ? "right"
                                            : layout === "sidebarRight" || layout === "headerRight"
                                                ? "left"
                                                : "full"
                                    }
                                />
                                {(layout === "sidebarLeft" ||
                                    layout === "sidebarRight" ||
                                    layout === "headerLeft" ||
                                    layout === "headerRight") && (
                                    <Sidebar
                                        showFiltering={
                                            layout === "sidebarLeft" || layout === "sidebarRight"
                                        }
                                        position={
                                            layout === "sidebarLeft" || layout === "headerLeft"
                                                ? "left"
                                                : "right"
                                        }
                                        showMapViewFilter={!!project.configs.mapViewFilter && project.configs.mapViewFilter.length > 0}
                                        style={customStyles.sidebarStyle}
                                        poiPageStyle={customStyles.poiPageStyle}
                                        loading={loading}
                                        expandable={project?.configs?.sidebarCollapse}
                                        layout={layout}
                                        setCustomStyles={setCustomStyles}
                                        projectConfigs={project.configs}
                                        sidebarOpen={sidebarOpen}
                                        toggleSidebar={toggleSidebar}
                                        sidebarRef={sidebarRef}
                                    />
                                )}
                            </section>
                        </div>
                        <ConfigModal basemaps={basemaps} projectConfigs={project.configs}/>
                        <ShareModal
                            open={shareModal.open}
                            onClose={shareModal.handleClose}
                            shortUrl={shareModal.shortUrl}
                        />
                        <GalleryModal
                            type={galleryModal.type}
                            open={galleryModal.open}
                            onClose={galleryModal.handleClose}
                            medias={galleryModal.medias}
                        />
                         <StreetViewModal
                            open={streetViewModal.open}
                            onClose={handleCloseStreetView}
                            streetView={streetViewModal.medias[0]}
                            sidebarStyle={customStyles.sidebarStyle}
                            sidebarCollapse={project?.configs?.sidebarCollapse}
                        />
                        {openAppInfoModal && (
                            <AppInfoModal
                                open={openAppInfoModal}
                                onClose={handleModalClose}
                                project={project}
                                appInfoModal={project.configs.appInfoModal}
                            />
                        )}
                        {openTutorial && (
                            <Tutorial
                                handleOpenTutorial={handleOpenTutorial}
                                layout={layout}
                            />
                        )}
                    </>
                );
            }}
        </AppContext.Consumer>
    );
}

DesktopLayout.propTypes = {};

export default DesktopLayout;
