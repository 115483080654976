import React, { useContext, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormControl, InputGroup } from "react-bootstrap";
import {Filters as CoreFilters, SearchBox, Suggestions} from "@mappr/react-lib";
import Downshift from "downshift";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context";
import Filter from "./Filters/Filter";

const Search = (props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [initialInputValue, setInitialInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const { projectConfigs } = useContext(AppContext);

  let searchInputTimeout = null;

  const handleSearchInput = (inputValue, onChange, stateAndHelpers) => {
    if (stateAndHelpers.selectedItem) {
      return;
    }
    setInputValue(inputValue);
    setInitialInputValue(inputValue);

    let str = inputValue.split(" ").filter((s) => s.length >= 2);

    searchInputTimeout = setTimeout(() => {
      onChange(str.length ? str.join(" ") : "");
    }, 500);
  };

  const handleSuggestionSelect = (
    token,
    onSelect,
    isGeocoder,
    stateAndHelpers
  ) => {
    stateAndHelpers.clearSelection();
    setInputValue("");
    setInitialInputValue("");
    onSelect(token, isGeocoder);
  };

  const stateReducer = (state, changes, suggestions) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownArrowDown:
      case Downshift.stateChangeTypes.keyDownArrowUp: {
        const selectedItem = suggestions[changes.highlightedIndex];
        if (selectedItem) {
          setInputValue(selectedItem.token);
        }

        return {
          ...changes,
          inputValue: selectedItem ? selectedItem.token : state.inputValue,
        };
      }
      default:
        return changes;
    }
  };

  const handleFocus = () => {
    props.onFiltersExpandToggle && props.onFiltersExpandToggle(false);
    setInputFocused(true);
  };
  const handleBlur = () => {
    props.onFiltersExpandToggle && props.onFiltersExpandToggle(true);
    setInputFocused(false);
  };

  const getFormattedText = (text) => {
    const words = initialInputValue.trim().replace(/\\/g, "\\\\").split(" ");
    const regexp = new RegExp(`(?:${words.join("|")})`, "ig");
    return text.replace(regexp, "<strong>$&</strong>");
  };

  return (
      <>
    <SearchBox geocoding={props.geocoding}>
      {({ searchText, searchMasks }, { onSearch, onUpdate, onRemove }) => (
        <Suggestions limit={props.suggestionsLimit}>
          {(
            { suggestions, geocoderResults, geocoderPOIsResults },
            { onSelect }
          ) => {
            const geocoderSuggestions = geocoderResults.map((item) => ({
              token: item.place_name,
              isGeocoder: true,
            }));
            const geocoderPOIsSuggestions = geocoderPOIsResults.map((item) => ({
              token: item.place_name,
              isGeocoder: true,
            }));
            const allSuggestions = [
              ...suggestions,
              ...geocoderSuggestions,
              ...geocoderPOIsSuggestions,
            ];
            return (
              <Downshift
                inputValue={inputValue}
                onChange={(selection, stateAndHelpers) =>
                  selection &&
                  handleSuggestionSelect(
                    selection.token || selection.guid,
                    onSelect,
                    selection.isGeocoder,
                    stateAndHelpers
                  )
                }
                onInputValueChange={(inputValue, stateAndHelpers) =>
                  handleSearchInput(inputValue, onUpdate, stateAndHelpers)
                }
                itemToString={(item) => (item ? item.token : "")}
                stateReducer={(state, changes) =>
                  stateReducer(state, changes, allSuggestions, onSelect)
                }
              >
                {({
                  getInputProps,
                  getItemProps,
                  getLabelProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  highlightedIndex,
                  selectedItem,
                }) => (
                  <div
                    className={`form-group form-group-custom-autocomplete position-relative z-index-1 ${
                      props.isInSubheader ? "mb-0" : ""
                    } ${
                      isOpen &&
                      inputValue.length >= 2 &&
                      (suggestions.length || geocoderSuggestions.length || geocoderPOIsSuggestions.length)
                        ? "expanded"
                        : ""
                    }`}
                  >
                    <InputGroup>
                      {searchMasks && searchMasks.length ? (
                        <InputGroup.Prepend
                          className={`${inputFocused ? "focus" : ""} ${
                            props.isInSubheader
                              ? "bg-primary-darkest border-primary-darkest"
                              : ""
                          }`}
                          style={{ direction: "rtl" }}
                        >
                          <div className="d-flex" style={{ direction: "ltr" }}>
                            {searchMasks.map((mask) => (
                              <InputGroup.Text
                                key={mask}
                                className="font-weight-bold"
                                onClick={() => {
                                  onRemove(mask);
                                }}
                              >
                                {mask}
                                <span className="ml-2 h-100">&times;</span>
                              </InputGroup.Text>
                            ))}
                          </div>
                        </InputGroup.Prepend>
                      ) : null}
                      <FormControl
                        className={`search ${
                          props.isInSubheader
                            ? "text-white bg-primary-darkest border-primary-darkest"
                            : ""
                        }`}
                        placeholder={t("search.placeholder")}
                        {...getInputProps()}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      <InputGroup.Append
                        className={
                          props.isInSubheader
                            ? "bg-primary-darkest border-primary-darkest"
                            : ""
                        }
                      >
                        <InputGroup.Text
                          className={
                            props.isInSubheader
                              ? "bg-primary-darkest border-primary-darkest"
                              : ""
                          }
                        >
                          <i className="icon icon-search" />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {(isOpen &&
                      inputValue.length >= 2 &&
                      (suggestions.length || geocoderSuggestions.length || geocoderPOIsSuggestions.length)) ||
                    geocoderPOIsSuggestions.length ? (
                      <>
                        <ul
                          className={`custom-autocomplete list-unstyled ${
                            props.isInSubheader
                              ? "text-white bg-primary-darkest border-primary-darkest"
                              : "position-absolute w-100"
                          }`}
                          {...getMenuProps()}
                        >
                          {suggestions.length && (geocoderSuggestions.length || geocoderPOIsSuggestions.length) ? (
                            <li>
                              <small className="text-uppercase font-weight-bold">
                                <i className="icon icon-location_pin mr-2" />{" "}
                                {t("search.pois")}
                              </small>
                            </li>
                          ) : null}
                          {suggestions.map((suggestion, index) => {
                            return (
                              <li
                                key={suggestion.token}
                                {...getItemProps({
                                  key: suggestion.token,
                                  index,
                                  item: suggestion,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex === index
                                        ? "var(--primary)"
                                        : "transparent",
                                  },
                                })}
                              >
                                {projectConfigs.searchType === "poi" ? (
                                  ReactHtmlParser(
                                    getFormattedText(
                                      suggestion.properties[
                                        projectConfigs?.poiTitleField
                                      ]
                                    )
                                  )
                                ) : (
                                  <>
                                    {ReactHtmlParser(
                                      getFormattedText(suggestion.token)
                                    )}
                                    ({suggestion.num})
                                  </>
                                )}
                              </li>
                            );
                          })}
                          {geocoderResults.length ? (
                            <li className="mt-3">
                              <small className="text-uppercase font-weight-bold">
                                <i className="icon icon-home mr-2" />
                                {t("search.addresses")}
                              </small>
                            </li>
                          ) : null}
                          {geocoderSuggestions.map((suggestion, index) => {
                            return (
                              <li
                                key={suggestion.token}
                                {...getItemProps({
                                  key: suggestion.token,
                                  index: suggestions.length + index,
                                  item: suggestion,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex ===
                                      suggestions.length + index
                                        ? "var(--primary)"
                                        : "transparent",
                                  },
                                })}
                              >
                                {ReactHtmlParser(
                                  getFormattedText(suggestion.token)
                                )}
                              </li>
                            );
                          })}

                          {geocoderPOIsResults.length ? (
                            <li className="mt-3">
                              <small className="text-uppercase font-weight-bold">
                                <i className="icon icon-home mr-2" />
                                {t("search.pois")}
                              </small>
                            </li>
                          ) : null}
                          {geocoderPOIsSuggestions.map((suggestion, index) => {
                            return (
                              <li
                                key={suggestion.token}
                                {...getItemProps({
                                  key: suggestion.token,
                                  index: suggestions.length + geocoderResults.length + index,
                                  item: suggestion,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex ===
                                      suggestions.length + geocoderResults.length + index
                                        ? "var(--primary)"
                                        : "transparent",
                                  },
                                })}
                              >
                                {ReactHtmlParser(
                                  getFormattedText(suggestion.token)
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                )}
              </Downshift>
            );
          }}
        </Suggestions>
      )}
    </SearchBox>
        <CoreFilters>
          {({filters, loading, selectedValues, initialFilters}, {onToggle, onSelect,removeAllFilters}) =>
              <button className="nav-link btn btn-primary"
                      onClick={()=>{
                        removeAllFilters()
                        document.location.reload()
                      }}
              >
                Retour
              </button>
          }

        </CoreFilters>

        </>

  );
};
export default Search;
