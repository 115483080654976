import React from "react";
import PropTypes from "prop-types";
// import POIThumbnail from '../POIThumbnail/POIThumbnail';
import "./POICard.scss";
import {useContext} from "react";
import {AppContext} from "../../../context";

function POICard(props) {
  const {
    domain,
    compact,
    image,
    fit,
    iconType,
    title,
    subTitle,
    isActive,
    onSelect,
    onHoverOn,
    onHoverOff,
  } = props;

  const { mprApiUrl } = useContext(AppContext);

  return (
    <div
      onClick={onSelect}
      onMouseEnter={onHoverOn}
      onMouseLeave={onHoverOff}
      className={`card ${
        isActive ? "active" : compact ? "bg-transparent" : ""
      } ${compact ? "card-compact h-100 w-14 border-0 p-1" : ""}`}
    >
      <img
        src={`${mprApiUrl}/projects/${domain}/image/512x512/${image}`}
        className={"card-img-top img-" + fit}
        alt="..."
      />
      <div className={`card-body ${compact ? "px-0 pt-1 pb-0" : ""}`}>
        <h5 className={`card-title text-truncate ${compact ? "mb-0" : "mb-3"}`}>
          <i
            className={`ic icon text-primary align-middle mr-1 float-left ic-${iconType}`}
          />
          {title}
        </h5>
        <p className={`card-text text-truncate ${compact ? "small" : ""}`}>
          {subTitle}
        </p>
      </div>
    </div>
  );
}

POICard.propTypes = {
  id: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
  image: PropTypes.string,
  iconType: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};
POICard.defaultProps = {
  compact: false,
};

export default POICard;
