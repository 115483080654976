import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import AppInfoPage from "./AppInfoPage/AppInfoPage";
import PageIndicators from "../../PageIndicators/PageIndicators";
import "./AppInfoModal.scss";
import { useTranslation } from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import AppInfoPageTest from "./AppInfoPage/AppInfoPageTest";



function AppInfoModal(props) {
  const { domain } = props.project.configs;
  const { t } = useTranslation();
  const { lang } = useParams();
  const [page, setPage] = useState(0);
  const appInfoModal = props.appInfoModal[lang];
  const isFullScreen = props.project.configs.appInfoModalFullscreen
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => Math.min(prevZoom * 1.2, 2)); // Limite le zoom maximal à 3x
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom / 1.2, 1)); // Limite le zoom minimal à 1x
  };

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      // Scrolling up, zoom in
      handleZoomIn();
    } else {
      // Scrolling down, zoom out
      handleZoomOut();
    }
  };




  function resetState() {
    setPage(0);
  }

  function getDialogClassName(){
    if(isFullScreen){
      return "modal-fullscreen"
    }
    return ""
  }

  return (
    <Modal
      id="modal-app-info"
      className="AppInfoModal"
      show={props.open}
      centered={true}
      scrollable={true}
      onHide={props.onClose}
      onExited={resetState}
      size="lg"
      dialogClassName={getDialogClassName()}
    >
      <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
      >
        <Modal.Title className={"w-100 text-center"}
                     style={{
                       fontFamily: "pencil-child,serif"
                     }}
        >
          Bienvenue In Seine-Saint-Denis</Modal.Title>
        <Button
          className="close text-muted"
          onClick={props.onClose}
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="icon icon-close" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {appInfoModal &&
          appInfoModal.map((info, index) => (
            <AppInfoPageTest
              key={`page-${index}`}
              shown={page === index}
              title={info.title}
              onClose ={props.onClose}
              zoomLevel = {zoomLevel}
              //  image={`${mprApiUrl}/${domain}/app-info/${info.image}`}
              image={`https://cloud.squidex.io/api/assets/${domain}/${info.image}`} //TODO: change to api url when image is saved during import
              description={info.description}
            />
          ))}
      </Modal.Body>
      <Modal.Footer className="justify-content-center flex-column">
        <div>
          <PageIndicators
            count={appInfoModal && appInfoModal.length}
            activeIndex={page}
            onSelect={(index, ev) => setPage(index)}
          />
        </div>
        <div>
          {appInfoModal && appInfoModal.length > 1 ? (
            <Button
              className="mb-2"
              variant="primary"
              onClick={() => setPage(page + 1)}
            >
              {t("appInfo.buttons.next")}
            </Button>
          ) : (
            <Button className="mb-2 mr-1 modalBtn" variant="primary" onClick={props.onClose}
                    style={{
                      background: "#fff",
                      padding: "10px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                      border: "solid 2px #000000",
                      textAlign: "center",
                      fontFamily: "pencil-child,serif",
                      color: "#000000",
                      borderRadius: "3px",
                      marginLeft: "10px",
                      transition: "color 0.3s ease, border-color 0.3s ease"
                    }}
            >
              Tout découvrir
            </Button>
          )}

          <Button className="mb-2 mr-1 modalBtn" variant="primary" onClick={handleZoomIn}
                  style={{
                    background: "#fff",
                    padding: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                    border: "solid 2px #000000",
                    textAlign: "center",
                    fontFamily: "pencil-child,serif",
                    color: "#000000",
                    borderRadius: "3px",
                    marginLeft: "10px",
                    transition: "color 0.3s ease, border-color 0.3s ease"
                  }}
          >+</Button>
          <Button className="mb-2 mr-1 modalBtn" variant="primary" onClick={handleZoomOut}
                  style={{
                    background: "#fff",
                    padding: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                    border: "solid 2px #000000",
                    textAlign: "center",
                    fontFamily: "pencil-child,serif",
                    color: "#000000",
                    borderRadius: "3px",
                    marginLeft: "10px",
                    transition: "color 0.3s ease, border-color 0.3s ease"
                  }}
          >-</Button>
        </div>

      </Modal.Footer>
    </Modal>
  );
}

AppInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
AppInfoModal.defaultProps = {
  open: false,
};

export default AppInfoModal;
