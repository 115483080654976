import React, {useEffect} from "react";
import PropTypes from "prop-types";
import ListHeader from "../../../components/Dataset/ResultList/ListHeader";
import ResultList from "../../../components/Dataset/ResultList/ResultList";
import ListLoader from "../../../components/loaders/ListLoader/ListLoader";
import CardsLoader from "../../../components/loaders/CardsLoader/CardsLoader";
import {AppContext} from "../../../context";
import POIDetails from "../../../components/POI/POIDetails/POIDetails";
import Search from "../SearchAndFilters/Search";
import Filters from "../SearchAndFilters/Filters/Filters";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import MapViewFilter from "../SearchAndFilters/MapViewFilter/MapViewFilter";
import {useMap} from '@mappr/react-lib';


const style = {
    right: 0,
    overflow: "hidden",
};

function Sidebar(props) {
    const {t} = useTranslation();
    const {map} = useMap();
    const {expandable, position, layout, sidebarOpen, toggleSidebar, sidebarRef} = props;

    const expandableStyle = expandable ? {
        position: sidebarOpen ? "absolute" : "fixed",
        bottom: "0",
        left: position === "left" && "0",
        display: sidebarOpen ? "block !important" : "none !important",
        visibility: sidebarOpen ? 'visible' : 'hidden',
        zIndex: '9'

    } : {}

    useEffect(() => {
        if (map && expandable) {
            const padding = ['sidebarRight', 'headerRight'].includes(
                layout,
            )
                ? {right: sidebarOpen ? sidebarRef?.current?.offsetWidth : 0}
                : {left: sidebarOpen ? sidebarRef?.current?.offsetWidth : 0};
            map.easeTo({
                padding: padding,
                duration: 500,
            });
        }
    }, [sidebarOpen, sidebarRef, map, expandable, layout]);


    return (
        <>
            <aside
                ref={sidebarRef}
                id="sidebar"
                className={`col-4 px-0 h-100 flex-column sidebar ${sidebarOpen ? "d-flex" : ""} ${
                    position === "left"
                        ? "order-1 border-right"
                        : "order-2 border-left"
                }`}
                style={{...props.style, ...style, ...expandableStyle}}
            >
                {props.loading ? (
                    <>
                        {props.showFiltering && (
                            <div className="w-100 mb-3 px-3 py-2 align-self-start">
                                SearchAndFiltersLoader
                                {/*<SearchAndFiltersLoader showFooter={false}/>*/}
                            </div>
                        )}
                        <AppContext.Consumer>
                            {({listViewType}) =>
                                listViewType.value === "list" ? (
                                    <ListLoader/>
                                ) : (
                                    <CardsLoader arrangement="vertical"/>
                                )
                            }
                        </AppContext.Consumer>
                    </>
                ) : (
                    <>
                        {props.showFiltering && (
                            <div className="w-100 mb-3 px-3 py-2 align-self-start">
                                {props.showMapViewFilter && <MapViewFilter/>}
                                {props.showMapViewFilter ? (
                                    <div
                                        className="d-inline-block float-right"
                                        style={{width: "82%"}}
                                    >
                                        <Search
                                            suggestionsLimit={5}
                                            isInSubheader={false}
                                            geocoding={true}
                                        />
                                    </div>
                                ) : (
                                    <Search
                                        suggestionsLimit={5}
                                        isInSubheader={false}
                                        geocoding={true}
                                    />
                                )}
                                <Filters isInSubheader={false} expandable={true}/>
                            </div>
                        )}
                        <div className="border-bottom">
                            <ListHeader/>
                        </div>
                        <ResultList/>
                    </>
                )}
                <POIDetails
                    fixedHeader={false}
                    printable={true}
                    style={props.poiPageStyle}
                />
                {expandable && (
                    <Button onClick={toggleSidebar} className="rounded-0">
                        {t("collapse_panel")}
                    </Button>
                )}
            </aside>
            {!sidebarOpen && expandable && (
                <div
                    className={`sidebar-toggle-container cursor-pointer h-100 bg-primary text-main  ${
                        position === "left" ? "left-sidebar" : "right-sidebar"
                    }`}
                    onClick={toggleSidebar}
                    aria-sidebarOpen={sidebarOpen}
                >
                    <span className="sidebar-toggle"/>
                </div>
            )}
        </>
    );
}

Sidebar.propTypes = {
    position: PropTypes.oneOf(["left", "right"]).isRequired,
    style: PropTypes.object,
    poiPageStyle: PropTypes.object,
    showFiltering: PropTypes.bool,
    loading: PropTypes.bool,
};

Sidebar.defaultProps = {
    position: "right",
    showFiltering: true,
    loading: true,
};

export default Sidebar;
