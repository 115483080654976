import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { AppContext } from "../../../../context";
import { useTranslation } from "react-i18next";

const DateFilter = (props) => {
  const { t } = useTranslation();
  const { name, onSelect, setShow, range, selectedValues } = props;
  const isMobile =
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1;
  const [minDate, setMinDate] = useState(moment("01-01-2000", "DD-MM-YYYY"));
  const [maxDate, setMaxDate] = useState(moment());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();

  useEffect(() => {
    if (range) {
      if (
        selectedValues &&
        selectedValues[name] &&
        selectedValues[name].length
      ) {
        const start = moment(selectedValues[name][0]);
        const end = moment(selectedValues[name][1]);

        setStartDate(start);
        setEndDate(end);
      }

      setMinDate(moment(range.min_date, "DD-MM-YYYY").add(-1, "days"));
      setMaxDate(moment(range.max_date, "DD-MM-YYYY").add(1, "days"));
    }
  }, [range]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");
      onSelect(name, start, end);
    }
  }, [startDate, endDate]);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const resetDate = () => {
    setShow(false);
    setStartDate();
    setEndDate();
    onSelect(name);
  };

  const dateItem = (
    <Dropdown.Item>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date"
        endDate={endDate}
        endDateId="end_date"
        startDatePlaceholderText={t("search.filters.start_date")}
        endDatePlaceholderText={t("search.filters.end_date")}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        isOutsideRange={(date) => date.isBefore(minDate)}
        initialVisibleMonth={() => moment(minDate)}
        minDate={minDate}
        maxDate={maxDate}
        hideKeyboardShortcutsPanel={true}
        onClose={() => setShow(false)}
        numberOfMonths={1}
      />
      <div className="clear" onClick={resetDate}>
        {t(`search.filters.clear`)}
      </div>
    </Dropdown.Item>
  );
  const dropdownMenuContents = (
    <AppContext.Consumer>
      {({ layout }) => {
        return (
          <Dropdown.Menu
            as="ul"
            className={
              "list-group  date-range " +
              (layout === 2 && !isMobile ? "dropdown-menu-right" : "")
            }
            flip={props.flipDropdown}
          >
            {dateItem}
          </Dropdown.Menu>
        );
      }}
    </AppContext.Consumer>
  );

  if (props.containerId) {
    let container = document.getElementById(props.containerId);
    if (!container) {
      container = document.createElement("div");
      container.id = props.containerId;
      container.style.position = "absolute";
      container.style.left = 0;
      container.style.top = 0;
      container.style.zIndex = "9999";
      container.style.transform = "translate3D(0px,32px,0px)";
      document.body.appendChild(container);
    }

    return ReactDOM.createPortal(dropdownMenuContents, container);
  } else {
    return dropdownMenuContents;
  }
};
export default DateFilter;
