import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import POICard from '../../../POI/POICard/POICard';
import Pagination from './Pagination';
import {AppContext} from '../../../../context';

function CardView({items, pageCount, currentPage, setPage, selectItem, hoverItem, hoveredItem, history}) {
    const onItemClick = (item, locale) => {
        selectItem(item.guid)
        if (history) {
            history.push({
                pathname: `/${locale.value}/map`,
                hash: window.location.hash,
                state: {prevPath: history.location.pathname}
            });
        }
    };

    return (
        <AppContext.Consumer>
            {({locale, domain, projectConfigs}) => (
                <div className="px-3 overflow-auto w-100 pt-3" style={{height: 'calc(100% - 40px)'}}>
                    <div className="row">
                        {items.map((item, index) => {
                            const iconValue = item.properties[projectConfigs.iconFieldName];
                            const iconMap = iconValue ? projectConfigs.iconMapping.find(_ => _.value === iconValue) : null
                            let icon = iconMap ? iconMap.iconName : 'material_ic_location_on_48px'
                            if (icon && icon.match(/([0-9a-fA-F]{6})$/)) {
                                icon = icon.substr(0, icon.lastIndexOf('_'));
                            }
                            const title = item.properties[projectConfigs.poiTitleField] || ReactHtmlParser('&nbsp;');
                            let image = item.properties.mediasets && item.properties.mediasets.main && item.properties.mediasets.main.filePath ? item.properties.mediasets.main.filePath.split('/' + domain + '/').join('') : null;
                            const subTitle = projectConfigs.poiSubtitleFields.map((field) => {
                                return item.properties[field.fieldName]
                            }).join(',')

                            if (!image) {
                                image = projectConfigs.appLogos[0] && projectConfigs.appLogos[0].image.filePath.split('/' + domain + '/').join('');
                            }
                            return (
                                <div key={item.guid}
                                     className={`col-sm-12 col-md-6 mb-2 ${index % 2 ? 'pl-md-1' : 'pr-md-1'}`}>
                                    <POICard id={item.guid} index={index}
                                             isActive={item.guid === hoveredItem?.guid}
                                             iconType={icon}
                                             title={title}
                                             subTitle={subTitle}
                                             image={image}
                                             fit={projectConfigs.poiMainImageFit}
                                             onSelect={() => onItemClick(item, locale)}
                                             onHoverOn={()=>hoverItem(item, "on")}
                                             onHoverOff={()=>hoverItem(item, "off")}
                                             domain={domain}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="col d-sm-block d-lg-flex justify-content-center text-center py-2">
                            <div className="btn-group btn-group-sm btn-group-pagination" role="group"
                                 aria-label="Pagination">
                                <Pagination pageCount={pageCount} currentPage={currentPage} onPageChange={setPage}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
}

CardView.propTypes = {}

export default CardView
