import {MapLegend} from "@mappr/react-lib";
import React from "react";
import LegendDropdown from "./LegendDropdown";

export default function LegendMenu(props) {
    const {dropdownStyle} = props;

    return (
        <MapLegend>
            {({showLegend, legendItems, isMapLegendOpen, legendTitle}) => {
                return (
                    showLegend && (
                        <LegendDropdown
                            items={legendItems}
                            style={dropdownStyle}
                            initiallyOpen={isMapLegendOpen}
                            legendTitle={legendTitle}
                        />
                    )
                );
            }}
        </MapLegend>
    );
}
