import React from "react";
import {useTranslation} from "react-i18next";

export const DrawPopUp = ({draw}) => {
    const {t} = useTranslation();
    return (
        <div className={'measurement border-primary'}>
            <div className="calculation-box">
                <div
                    id="distance">{draw.mode === 'Polygon' ? t("draw.perimeter") : t("draw.distance")}: {draw.distance} km
                </div>
                {!!draw.area && <div id="zone">{t("draw.area")}: {draw.area} km²</div>}
            </div>
        </div>
    )
}
