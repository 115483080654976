export const reducer = (state, update) => {
    switch (update.prop) {
        case "theme":
            return {
                ...state,
                theme: update.value
            };
        case "layout":
            return {
                ...state,
                layout: update.value
            };
        case "baseMap":
            return {
                ...state,
                baseMap: update.value
            };
        case "locale":
            return {
                ...state,
                locale: {
                    ...state.locale,
                    value: update.value,
                },
            };
        case "configModal":
            return {
                ...state,
                configModal: {
                    ...state.configModal,
                    ...update.value,
                },
            };
        case "shareModal":
            return {
                ...state,
                shareModal: {
                    ...state.shareModal,
                    ...update.value,
                },
            };

        case "galleryModal":
            return {
                ...state,
                galleryModal: {
                    ...state.galleryModal,
                    ...update.value,
                },
            };


        case "listViewType":
            return {
                ...state,
                listViewType: {
                    ...state.listViewType,
                    value: update.value,
                },
            };
        default:
            return {
                ...state,
                [update.prop]: update.value
            }
    }
}
