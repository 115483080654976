import React from 'react';
import PropTypes from 'prop-types';
import {Filters as CoreFilters} from '@mappr/react-lib';
import {useTranslation} from "react-i18next";
import Filter from "./Filter";
import Layouts from "../../../Layouts";
import ExpandCollapse from "../../../../components/ExpandCollapse";
import {useParams, useLocation} from "react-router-dom";
import {getDomain} from "../../../../utils/envUtils";

function Filters(props) {
    const {t} = useTranslation();
    const {lang} = useParams();
    const currentTheme = sessionStorage.getItem(`${getDomain()}-mpr-theme`);
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const hiddenFilters = searchParams.get('hiddenFilters') || [];

    if (props.isInSubheader) {
        return (
                <ul className="nav nav-filters mr-auto col-9 flex-row flex-nowrap horizontal-scroll mb-n3">

                    <li className="text-white text-smaller mr-2 line-height-2">
                        {t('search.filterBy')}
                    </li>

                    <CoreFilters>
                        {({filters, loading, selectedValues, initialFilters}, {onToggle, onSelect}) =>
                            !loading && initialFilters.map((filter, index) =>
                                !hiddenFilters.includes(filter.name) ? <Filter isInSubheader={props.isInSubheader}
                                                                               key={'filter-' + index}
                                                                               lang={lang}
                                                                               selectedValues={selectedValues}
                                                                               {...filter}
                                                                               filters={filters}
                                                                               onToggle={onToggle}
                                                                               onSelect={onSelect}
                                /> : null
                            )
                        }
                    </CoreFilters>
                    {/*{mapViewFilterOptions && (*/}
                    {/*    <CoreMapViewFilter>*/}
                    {/*        {({mapProps}, {onChange}) =>*/}
                    {/*            <MapViewFilter isInSubheader={props.isInSubheader} mapProps={mapProps}*/}
                    {/*                           options={[...mapViewFilterOptions]} onChange={onChange}/>*/}
                    {/*        }*/}
                    {/*    </CoreMapViewFilter>*/}
                    {/*)}*/}
                </ul>
        );
    } else {
        const theFilters = (
            <>
                <CoreFilters>
                    {({filters, loading, selectedValues, initialFilters}, {onToggle, onSelect}) =>
                        !loading && initialFilters.map((filter, index) =>
                            !hiddenFilters.includes(filter.name) ? <Filter isInSubheader={props.isInSubheader}
                                    key={'filter-' + index}
                                    lang={lang}
                                    selectedValues={selectedValues}
                                    {...filter}
                                    filters={filters}
                                    onToggle={onToggle}
                                    onSelect={onSelect}
                            /> : null
                        )
                    }
                </CoreFilters>
                {/*{mapViewFilterOptions && (*/}
                {/*    <CoreMapViewFilter>*/}
                {/*        {({mapProps}, {onChange}) =>*/}
                {/*            <MapViewFilter isInSubheader={props.isInSubheader} mapProps={mapProps}*/}
                {/*                           options={mapViewFilterOptions} onChange={onChange}/>*/}
                {/*        }*/}
                {/*    </CoreMapViewFilter>*/}
                {/*)}*/}
            </>
        );

        const listOfFilters = (
            <Layouts>
                {(mode) => mode === 'mobile' ?
                    theFilters
                    :
                    props.expandable ? (
                        <ExpandCollapse expandOnBodyClick={true} previewHeight="46" expanded={props.expanded}
                                        onExpandClick={() => props.onExpandToggle(true)}
                                        onCollapseClick={() => props.onExpandToggle(false)}
                                        expandText={<button className="btn btn-link border-left-0 text-right px-0"><i
                                            className="icon icon-arrow_down mr-2"/> {t('search.seeMore')}
                                        </button>}
                                        collapseText={
                                            <button className="btn btn-link text-smaller border-left-0 text-right px-0">
                                                <i className="btn btn-link text-smaller border-left-0 text-right px-0"/>
                                                <i className="icon icon-arrow_up mr-2"/>
                                                {t('search.seeLess')}
                                            </button>}
                                        ellipsis={false}>
                            {theFilters}
                        </ExpandCollapse>
                    ) : (
                        <div>
                            {theFilters}
                        </div>
                    )
                }
            </Layouts>
        );
        return (
            <div className={'Filters ' + (props.isInSubheader ? '' : 'pt-2')}>
                <p className={'mb-2 ' + (props.isInSubheader ? 'mr-2 d-inline' : '') + (currentTheme === 'dark' ? 'text-primary' : '')}>{t('search.filterBy')}</p>
                {listOfFilters}
            </div>
        );
    }
}

Filters.propTypes = {
    isInSubheader: PropTypes.bool.isRequired,
    expandable: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    onExpandToggle: PropTypes.func
};
Filters.defaultProps = {
    isInSubheader: false,
    expandable: false,
    expanded: false,
    onExpandToggle: () => {
    }
};

export default Filters;
