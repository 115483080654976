import React, {useState} from "react";
import {Accordion, Button, Card, Col, Container, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function LegendDropdown({style, items, initiallyOpen = false, legendTitle}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(initiallyOpen);

    const styleMenu = {
        border: '1px solid #dededf',
        borderRadius: '0.25rem',
        boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.14)'
    }


    const getAccordionItem = ({title, url}, i) => {
        const key = "legend-item-" + i;
        return (
            <Card key={i}>
                <Card.Body className={'p-0'}>
                    {t(`project:${title}`) &&
                        <Card.Title>
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={key}
                                className={'w-100'}
                            >
                                {t(`project:${title}`)}
                            </Accordion.Toggle>
                        </Card.Title>
                    }
                    <Accordion.Collapse eventKey={key}>
                        <Image src={url} rounded/>
                    </Accordion.Collapse>
                </Card.Body>
            </Card>
        );
    };

    return (
        <Container
            className="dropup"
            style={style}
        >
            <Row>
                <Col className={'text-right'}>
                    <Button
                        id="layers-menu-dropdown"
                        variant="primary"
                        className={`btn-primary px-2 line-height-1-5`}
                        onClick={() => setOpen(!open)}
                    > {legendTitle ? t(`project:${legendTitle}`) : t("map.legend")}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className={'text-right'}>
                    {open &&
                        <div
                            style={{
                                maxHeight: "70vh",
                                minWidth: "300px",
                                fontSize: "5px",
                                ...styleMenu
                            }}
                            className="mt-1 overflow-auto bg-light"
                        >
                            <Accordion defaultActiveKey="legend-item-1">
                                {items.map((_, i) => getAccordionItem(_, ++i))}
                            </Accordion>
                        </div>
                    }

                </Col>
            </Row>
        </Container>
    );
}

export default LegendDropdown;
