import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Button,
  Col,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { AppContext } from "../../context";
import { FormGroup } from "react-bootstrap";
import "./Login.scss";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FullPageLoading from "../loaders/FullPageLoading/FullPageLoading";

function Login({ loading, error, onLogin, project }) {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    onLogin({ username, password });
  };

  return (
    <AppContext.Consumer>
      {({ mprApiUrl }) => (
        <Container fluid className="LoginContainer h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <Col md={6} lg={3} className="mt-n-5">
              {!loading && project.configs ? (
                <Form onSubmit={handleLogin}>
                  <div className="text-center mb-5">
                    <a
                      href={project.configs.appLogos[0].url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${mprApiUrl}/projects/${project.configs.domain}/logo/${project.configs.appLogos[0].image.fileName}`}
                        height="64"
                        alt=""
                      />
                    </a>
                  </div>

                  <FormGroup>
                    <FormLabel>{t("login.username")}</FormLabel>
                    <FormControl
                      placeholder={t("login.username")}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>{t("login.password")}</FormLabel>
                    <FormControl
                      type="password"
                      placeholder={t("login.password")}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>

                  <div className="text-right">
                    <Button type="submit">{t("login.button")}</Button>
                  </div>
                  {error && (
                    <Alert variant="danger" className="mt-4">
                      {t("login.error")}
                    </Alert>
                  )}
                </Form>
              ) : (
                <FullPageLoading />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </AppContext.Consumer>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
