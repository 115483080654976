import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CircularDiv = ({ id, libelle,onClick, isZoomed, zoomLevel, onHover, onLeave }) => {

    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleResize = () => {

        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isZoomed, zoomLevel]);

    const handleMouseEnter = () => {
        setIsHovered(true);
        if (onHover) {
            onHover();
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (onLeave) {
            onLeave();
        }
    };

    return (
        <div
            className="circularDivContainer"
        >
            <img
                src={`./img/${id}.png`}
                alt={id}
                className={"circleImgDiv"}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />

            <div className={"circularDivText"}
                 onClick={onClick}
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
            >
                {libelle}
            </div>
        </div>
    );
};

CircularDiv.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onHover: PropTypes.func,
    onLeave: PropTypes.func,
    isZoomed: PropTypes.bool.isRequired,
    zoomLevel: PropTypes.number.isRequired,
};

export default CircularDiv;
