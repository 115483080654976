import React from 'react';
import POIListItem from '../../../POI/POIListItem/POIListItem';
import Pagination from './Pagination';
import {AppContext} from '../../../../context';


function ListView({items, pageCount, currentPage, setPage, selectItem, hoverItem, hoveredItem, history}) {
    const onItemClick = (item, locale) => {
        selectItem(item.guid)
        if (history) {
            history.push({
                pathname: `/${locale.value}/map`,
                hash: window.location.hash,
                state: {prevPath: history.location.pathname}
            });
        }
    };

    return (
        <AppContext.Consumer>
            {({locale, domain, projectConfigs}) => (
                <ul className="list-group list-group-flush overflow-auto w-100"
                    style={{flex: '1 1', height: 'calc(100% - 40px)'}}>
                    {items.map((item, index) => {
                        const iconValue = item.properties[projectConfigs.iconFieldName]
                        const iconMap = iconValue ? projectConfigs.iconMapping.find(_ => _.value === iconValue) : null
                        let icon = iconMap ? iconMap.iconName : 'material_ic_location_on_48px'
                        if (icon && icon.match(/([0-9a-fA-F]{6})$/)) {
                            icon = icon.substr(0, icon.lastIndexOf('_'));
                        }
                        const title = item.properties[projectConfigs.poiTitleField]
                        let image = item.properties.mediasets && item.properties.mediasets.main && item.properties.mediasets.main.filePath ? item.properties.mediasets.main.filePath.split('/' + domain + '/').join('') : null;
                        const subTitle = projectConfigs.poiSubtitleFields.map((field) => {
                            return item.properties[field.fieldName]
                        }).join(',')

                        if (!image) {
                            image = projectConfigs.appLogos[0] && projectConfigs.appLogos[0].image.filePath.split('/' + domain + '/').join('');
                        }

                        return (
                            <POIListItem key={item.guid} id={item.guid} index={index}
                                         isActive={item.guid === hoveredItem?.guid}
                                         iconType={icon}
                                         title={title}
                                         subTitle={subTitle}
                                         image={image}
                                         onSelect={() => onItemClick(item, locale)}
                                         onHoverOn={()=>hoverItem(item, "on")}
                                         onHoverOff={()=>hoverItem(item, "off")}
                                         domain={domain}
                                         fit={projectConfigs.poiMainImageFit}
                            />
                        );
                    })}
                    <li className="list-group-item d-sm-block d-lg-flex justify-content-center text-center list-footer">
                        <div className="btn-group btn-group-sm btn-group-pagination" role="group"
                             aria-label="Pagination">
                            <Pagination pageCount={pageCount} currentPage={currentPage} onPageChange={setPage}/>
                        </div>
                    </li>
                </ul>
            )}
        </AppContext.Consumer>
    )
}

ListView.propTypes = {}

export default ListView
