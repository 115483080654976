import React from 'react';

export default function withSocialScripts(WrappedComponent) {
  return class extends React.Component {
    initFB() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '165580440798188', //'227090211393438',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.12'
        });
      };

      (function (d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }

    initTW() {
      window.twttr = (function (d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0],
          t = window.twttr || {};
        if (d.getElementById(id)) return t;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js, fjs);

        t._e = [];
        t.ready = function (f) {
          t._e.push(f);
        };

        return t;
      }(document, "script", "twitter-wjs"));
    }

    componentDidMount() {
      this.initFB();
      this.initTW();
    }

    componentWillUnmount() {

    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
