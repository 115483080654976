import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { SCREEN_SIZES } from "../config/constants";

const getMatchingModeForScreenWidth = () => {
  let matchingKey;
  const width = window.innerWidth;
  for (let [key, value] of Object.entries(SCREEN_SIZES)) {
    if (width <= value[0]) {
      break;
    }
    matchingKey = key;
  }
  return matchingKey;
};

function Layouts({ autoTrack = true, currentMode, screenSizes, children }) {
  const [mode, setMode] = useState(getMatchingModeForScreenWidth());
  useEffect(() => {
    if (autoTrack) {
      autoUpdateMode();
      attachWindowResizeListener();
    }
    return () => {
      removeWindowResizeListener();
    };
  }, []);

  useEffect(() => {
    if (currentMode) {
      setMode(currentMode);
    }
  }, [currentMode]);

  useEffect(() => {
    if (autoTrack) {
      attachWindowResizeListener();
    } else {
      removeWindowResizeListener();
    }

    return () => {
      removeWindowResizeListener();
    };
  }, [autoTrack, mode]);

  const autoUpdateMode = () => {
    const currentMode = getMatchingModeForScreenWidth();
    if (currentMode !== mode) {
      setMode(currentMode);
    }
  };

  const attachWindowResizeListener = () => {
    removeWindowResizeListener();
    window.addEventListener("resize", autoUpdateMode);
  };

  const removeWindowResizeListener = () => {
    window.removeEventListener("resize", autoUpdateMode);
  };

  return (
    <div data-mode={mode} className="h-100 w-100">
      {children(mode)}
    </div>
  );
}

Layouts.propTypes = {
  currentMode: PropTypes.string,
  autoTrack: PropTypes.bool,
  screenSizes: PropTypes.object,
};

export default Layouts;
