import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
// import FilterItems from './FilterItems';
import DateFilter from "./DateFilter";
import RangeSliderFilter from "./RangeSliderFilter";
import { useTranslation } from "react-i18next";
import FilterItems from "./FilterItems";
import { AppContext } from "../../../../context";

function Filter(props) {
  const { t } = useTranslation();
  const { name, selectedValues, onSelect, type } = props;
  const filterId = name;
  const isActive = selectedValues?.[filterId]?.length > 0;
  const [show, setShow] = useState(false);
  const { projectConfigs } = useContext(AppContext);

  const handleToggle = (isOpen, event, metadata) => {
    if (isOpen || metadata.source !== "select") {
      setShow(isOpen);
    }
  };
  if (props.isInSubheader) {
    return (
      <AppContext.Consumer>
        {({ projectConfigs }) => {
          return (
            <Dropdown
              as="li"
              className="nav-item mx-2 position-relative"
              show={show}
              onToggle={handleToggle}
            >
              <Dropdown.Toggle
                as="a"
                role="button"
                className={`nav-link btn btn-primary${
                  isActive ? "" : "-lighter"
                } text-smaller`}
              >
                {t(`project:${filterId}`)}
                {isActive ? (
                  <span className="badge badge-light text-primary ml-2 mr-n2">
                    { type === "daterange" ||  type === "range" ? 1 : [...selectedValues[filterId]].length}
                  </span>
                ) : null}
              </Dropdown.Toggle>
              {props.type && props.type === "daterange" ? (
                <DateFilter
                  {...props}
                  containerId={`dropdown-${filterId}`}
                  flipDropdown={true}
                  onSelect={onSelect}
                  setShow={setShow}
                />
              ) : props.type && props.type === "range" ? (
                <RangeSliderFilter
                  {...props}
                  containerId={`dropdown-${filterId}`}
                  flipDropdown={true}
                  onSelect={onSelect}
                  setShow={setShow}
                />
              ) : (
                <FilterItems
                  {...props}
                  filterId={filterId}
                  containerId={`dropdown-${filterId}`}
                  flipDropdown={true}
                  setShow={setShow}
                  closeOnSelect={projectConfigs.filterLogic !== "or"}
                  groupSelectedFilterItems={
                    projectConfigs.groupSelectedFilterItems
                  }
                />
              )}
            </Dropdown>
          );
        }}
      </AppContext.Consumer>
    );
  } else {
    return (
      <Dropdown
        className="d-inline-block mr-2 mb-2"
        show={show}
        onToggle={handleToggle}
      >
        <Dropdown.Toggle
          variant={`primary${isActive ? "" : "-lighter"}`}
          data-display="static"
        >
          {t(`project:${filterId}`)}
          {isActive ? (
            <span className="badge badge-light text-primary ml-2 mr-n2">
              { type === "daterange" ||  type === "range" ? 1 : [...selectedValues[filterId]].length}
            </span>
          ) : null}
        </Dropdown.Toggle>
        {props.type && props.type === "daterange" ? (
          <DateFilter
            {...props}
            flipDropdown={false}
            onSelect={onSelect}
            setShow={setShow}
          />
        ) : props.type && props.type === "range" ? (
          <RangeSliderFilter
            {...props}
            flipDropdown={false}
            onSelect={onSelect}
            setShow={setShow}
          />
        ) : (
          <FilterItems
            {...props}
            filterId={filterId}
            flipDropdown={false}
            setShow={setShow}
            closeOnSelect={projectConfigs.filterLogic !== "or"}
          />
        )}
      </Dropdown>
    );
  }
}

Filter.propTypes = {
  isInSubheader: PropTypes.bool.isRequired,
};
Filter.defaultProps = {
  isInSubheader: false,
};

export default Filter;
