import React from 'react';
import PropTypes from 'prop-types';
import './POIListItem.scss';
import {useContext} from "react";
import {AppContext} from "../../../context";

function POIListItem(props) {
    const {image, iconType, title, subTitle, index, fit, isActive, onSelect, onHoverOn, onHoverOff, domain} = props;
    const { mprApiUrl } = useContext(AppContext);

    return (
        <li onClick={onSelect} onMouseEnter={onHoverOn} onMouseLeave={onHoverOff}
            className={`list-group-item media list-group-item-action d-block ${isActive ? 'active' : ''} ${index === 0 ? 'border-top-0' : ''}`}>
            <img src={`${mprApiUrl}/projects/${domain}/image/256x256/${image}`}
                 className={"rounded align-top img-" + fit}
                  alt="..."/>
            {/* <POIThumbnail mediaSetId={image.mediaSetId} id={image.id + '/main_image'} size={image.size} className="rounded align-top"/> */}
            <div className="media-body text-truncate d-inline-block w-75">
                <h5 className="mt-0 mb-1 text-truncate">
                    <i className={`ic icon text-primary align-middle mr-1 float-left ic-${iconType}`}/>
                    {title}
                </h5>
                <p className="text-truncate">
                    {subTitle}
                </p>
            </div>
        </li>
    );
}

POIListItem.propTypes = {
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    iconType: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isActive: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
};
POIListItem.defaultProps = {};

export default POIListItem;
