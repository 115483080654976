import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Map as CoreMap, MapControl} from "@mappr/react-lib";
import LayersMenu from "../../../components/Map/LayersMenu/LayersMenu";
import GeocoderPopup from "../../../components/GeocoderPopup/GeocoderPopup";
import StreetViewButton from "../../../components/Map/StreetViewButton/StreetViewButton";
import {AppContext} from "../../../context";
import PopUp from "../../../components/Map/PopUp/PopUp";
import {getDomain} from "../../../utils/envUtils";
import LegendMenu from "../../../components/Map/LegendMenu/LegendMenu";
import ModelToggleButton from "../../../components/Map/ModelToggleButton/ModelToggleButton";
import {useHistory} from "react-router";
import SecondaryLayerPopUp from "../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp";
import { DrawPopUp } from "../../../components/Map/DrawPopUp/DrawPopUp";

function Map(props) {
    const currentBaseMap = sessionStorage.getItem(`${getDomain()}-mpr-base-map`);
    const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
    const {
        mapCenter,
        mapZoom,
        minZoom,
        maxZoom,
        mapPitch,
        show3dSwitch,
        minPitch,
        maxPitch,
        showPolygonSwitch,
        sidebarCollapse
    } = props.projectConfigs;
    const [mapReady, setMapReady] = useState(false);
    const history = useHistory();
    let containerClass = "col-12";
    if (props.position === "left") {
        containerClass = `col-${sidebarCollapse ? "12" : "8"} left ${
            !props.expanded ? "" : "expanded"
        } order-1 h-100`;
    } else if (props.position === "right") {
        containerClass = `col-${sidebarCollapse ? "12" : "8"} right ${
            !props.expanded ? "" : "expanded"
        } order-2 h-100`;
    }
    useEffect(() => {
        if (currentBaseMap) {
            const basemapInfo = props.basemaps.filter(
                (basemap) => basemap.name === currentBaseMap
            );
            setCurrentBasemapInfo(basemapInfo[0]);
        }
    }, [currentBaseMap, props.basemaps]);

    const openStreetViewModal = (feature, methods) => {
        let obj = feature.properties;
        methods.setMedias([{embedUrl: obj.URL}]);

        return false;
    };

    const handleBasemapChange = () => {
        history.go(0);
    };

  return (
    <>
      <div id="map-container" className={`bg-grey p-0 ${containerClass}`}>
        <AppContext.Consumer>
          {({ streetViewModal }) => {
            return (
              currentBasemapInfo && (
                <CoreMap
                  lng={mapCenter.longitude}
                  lat={mapCenter.latitude}
                  zoom={mapZoom}
                  minZoom={minZoom}
                  maxZoom={maxZoom}
                  pitch={mapPitch || 45}
                  minPitch={minPitch || 0}
                  maxPitch={maxPitch || 60}
                  boundingBox={props.projectConfigs.boundingBox}
                  url={currentBasemapInfo && currentBasemapInfo.url}
                  models={props.projectConfigs.models || []}
                  geocoderPopupComponent={GeocoderPopup}
                  openStreetViewModal={(feature) =>
                    openStreetViewModal(feature, {
                      setMedias: streetViewModal.setMedias,
                    })
                  }
                  setMapReady={(isReady) => setMapReady(isReady)}
                  PopUpComponent={PopUp}
                  SecondaryLayerPopUpComponent={SecondaryLayerPopUp}
                  isBboxIncluded={
                    props.projectConfigs?.isBboxIncluded !== false
                  }
                  onBasemapChange={handleBasemapChange}
                />
              )
            );
          }}
        </AppContext.Consumer>
        {mapReady && (
          <MapControl position={"bottom-left"}>
            <LayersMenu buttonType="square" />
          </MapControl>
        )}

                {mapReady && show3dSwitch && (
                    <MapControl position={"bottom-left"}>
                        <ModelToggleButton/>
                    </MapControl>
                )}

                {mapReady && (
                    <MapControl position={"bottom-left"}>
                        <StreetViewButton/>
                    </MapControl>
                )}
                {mapReady && (
                    <MapControl position={"top-right"}>
                        <LegendMenu dropdownStyle={props.mapLegendStyle} />
                    </MapControl>
                )}
                {showPolygonSwitch &&
                  props.draw &&
                  (props.draw?.mode || props.draw?.area) && (
                    <DrawPopUp draw={props.draw} />
                  )}
            </div>
        </>
    );
}

Map.propTypes = {
    position: PropTypes.oneOf(["left", "right", "full"]).isRequired,
    loading: PropTypes.bool,
};

Map.defaultProps = {
    position: "left",
    loading: true,
};

export default Map;
