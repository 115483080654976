import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import './AppInfoPage.scss';
import CircularDiv from './CircularDiv';
import { Filters as CoreFilters } from '@mappr/react-lib';
import { Button, Image } from 'react-bootstrap';

const circlediv = [
    {
        id: 'shopping',
        libelle: 'Faire du Shopping',
        top: '46%',
        left: '34%'
    },
    {
        id: 'celebrer',
        libelle: 'Vivre les jeux',
        top: '39%',
        left: '37.5%'
    },
    {
        id: 'balader',
        libelle: 'Se balader',
        top: '65%',
        left: '59%'
    },
    {
        id: 'decouvrir',
        libelle: 'à découvrir',
        top: '48.5%',
        left: '42.5%'
    },
    {
        id: 'regaler',
        libelle: 'Se régaler',
        top: '69%',
        left: '63%'
    },
    {
        id: 'fete',
        libelle: 'Faire la fête, sortir',
        top: '25%',
        left: '43%',
    }
]


function AppInfoPageTest(props) {
    const { title, image, description } = props;
    const [isZoomed, setIsZoomed] = useState(false);
    const [hoveredDiv, setHoveredDiv] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });




    useEffect(() => {
        const handleResize = () => {
            if (hoveredDiv && isZoomed) {
                const x = mousePosition.x + hoveredDiv.offsetWidth / 2;
                const y = mousePosition.y + hoveredDiv.offsetHeight / 2;

                hoveredDiv.style.transformOrigin = `${x}px ${y}px`;
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isZoomed, hoveredDiv, mousePosition,props.zoomLevel]);


    const handleDivClick = (filters, onToggle, value) => {
        let filtersValues = [];
        console.log(value)
        filters.forEach((filter) => {
            if (filter.name === value) {
                filter.stats.forEach((stat) => {
                    filtersValues.push(stat.value);
                });
            }
        });
        onToggle(value, filtersValues);
        props.onClose();
    };


    useEffect(() => {
        const handleResize = () => {
            if (hoveredDiv && isZoomed) {
                const x = hoveredDiv.offsetLeft + hoveredDiv.offsetWidth / 2;
                const y = hoveredDiv.offsetTop + hoveredDiv.offsetHeight / 2;

                hoveredDiv.style.transformOrigin = `${x}px ${y}px`;
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isZoomed, hoveredDiv]);

    return (
        <div
            className={`AppInfoPage fade ${props.shown ? 'show' : 'd-none'}`}
        >
            <div
                className="image-container"
            >
                <img
                    src={'./img/Carte_SSD.png'}
                    alt="Map"
                    style={{
                        display: 'block',
                        maxWidth:'80vw',
                        maxHeight:'80vh',
                        width: 'auto',
                        height: 'auto',
                        margin: 'auto',
                        transform: `scale(${props.zoomLevel}) `,
                        transformOrigin: 'center center',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                />

                <CoreFilters>
                    {(
                        { loading, filters, initialFilters, selectedFilters },
                        { onToggle, onToggleValues, removeAllFilters }
                    ) => {
                        if (loading) {
                            return '';
                        }
                            return (
                                <div className={'testCtn'}>
                                    {circlediv.map((circle, index) => (
                                        <CircularDiv
                                            key={index}
                                            id={circle.id}
                                            libelle={circle.libelle}
                                            isZoomed={isZoomed}
                                            zoomLevel={props.zoomLevel}
                                            onClick={() => {
                                                removeAllFilters();
                                                handleDivClick(filters, onToggleValues, circle.id)
                                            }}
                                        />
                                    ))}
                                </div>
                            );

                    }}
                </CoreFilters>


                <div style={{
                        display: "flex",
                    flexDirection:"column",
                    position:"absolute",
                    right: "0px",
                    alignItems:"center"
                }}>
                    <img
                        src={'./img/logo%20version-bleucouleurs.png'}
                        alt={"x"}
                        style={{
                            width: '16vh',
                            height: 'auto'
                        }}
                    />
                    <img
                        src={'./img/logo_SSD-Tourisme_Q.jpg'}
                        alt={"x"}
                        style={{
                            width: '16vh',
                            height: 'auto'
                        }}
                    />
                    <img
                        src={'./img/Paris2024_Seine%20Saint%20Denis_Double06_RVB_Poly_FC-Double06-Poly_FC-Double06-Poly.png'}
                        alt={"x"}
                        style={{
                            width: '27vh',
                            height: '11vh'
                        }}
                    />
                </div>

            </div>


        </div>
    );
}

AppInfoPageTest.propTypes = {
    shown: PropTypes.bool,
    title: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
};
AppInfoPageTest.defaultProps = {
    shown: false,
};

export default AppInfoPageTest;
