import React from 'react';
import ListItemLoader from './ListItemLoader';

function ListLoader (props) {
  return (
    <div style={{ flex: '1 1', height: 'calc(100% - 40px)' }}>
      <div className="ListHeaderContents d-flex w-100 px-0 py-2" >
        <div className="col-4 align-self-start">
          <div className="placeholder animated placeholder-line-slim rounded d-inline-block"/>
        </div>
        <div className="col-8 align-self-end text-right">
          <div className="placeholder animated placeholder-tiny-square rounded d-inline-block mr-2"/>
          <div className="placeholder animated placeholder-tiny-square rounded d-inline-block"/>
        </div>
      </div>
      <ul className="list-group list-group-flush overflow-auto w-100"
         >
        <ListItemLoader/>
        <ListItemLoader/>
        <ListItemLoader/>
      </ul>
    </div>
  );
}

ListLoader.propTypes = {};
ListLoader.defaultProps = {};

export default ListLoader;
