import React from "react";
import ListHeader from "../../../components/Dataset/ResultList/ListHeader";
import ResultList from "../../../components/Dataset/ResultList/ResultList";
import CardsLoader from "../../../components/loaders/CardsLoader/CardsLoader";
import ListLoader from "../../../components/loaders/ListLoader/ListLoader";
import { AppContext } from "../../../context";
import {useHistory} from "react-router-dom";

function ListPage({ active, loading }) {
  const history = useHistory();

  if (loading) {
    return (
      <div
        className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${
          !this.props.active ? "page-hidden" : "page-active"
        }`}
      >
        <AppContext.Consumer>
          {({ listViewType }) =>
            listViewType.value === "list" ? (
              <ListLoader />
            ) : (
              <CardsLoader arrangement="vertical" />
            )
          }
        </AppContext.Consumer>
      </div>
    );
  }
  return (
    <div className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${!active ? 'page-hidden' : 'page-active'}`}>
      <div className="row h-100 px-3 pb-3">
        <div className="col-12 h-100 px-0">
          <ListHeader/>
          <ResultList history={history} isMobile={true}/>
        </div>
      </div>
    </div>
  );
}

export default ListPage;
