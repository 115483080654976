import React from "react";
import PropTypes from "prop-types";
import { Items } from "@mappr/react-lib";
import { AppContext } from "../../../context/index";
import ListView from "./Results/ListView";
import CardView from "./Results/CardView";
import ListLoader from "../../loaders/ListLoader/ListLoader";
import CardsLoader from "../../loaders/CardsLoader/CardsLoader";

function ResultList(props) {
  return (
    <AppContext.Consumer>
      {({ listViewType }) => (
        <Items>
          {({ loading, error, items, info, hoveredItem }, { setPage, setOrderBy, selectItem, hoverItem }) => {
            if (loading) {
              return listViewType.value === "list" ? (
                <ListLoader />
              ) : (
                <CardsLoader arrangement="vertical" />
              );
            }

            const pageCount = Math.ceil(info.count / info.limit);
            return listViewType.value === "list" ? (
              <ListView
                items={items}
                currentPage={info.page}
                pageCount={pageCount}
                setPage={setPage}
                hoveredItem={hoveredItem}
                selectItem={selectItem}
                hoverItem={props.isMobile ? ()=>{} : hoverItem}
                history={props.history}
              />
            ) : (
              <CardView
                items={items}
                currentPage={info.page}
                pageCount={pageCount}
                setPage={setPage}
                hoveredItem={hoveredItem}
                selectItem={selectItem}
                hoverItem={props.isMobile ? ()=>{} : hoverItem}
                history={props.history}
              />
            );
          }}
        </Items>
      )}
    </AppContext.Consumer>
  );
}

ResultList.propTypes = {};
ResultList.defaultProps = {};

export default ResultList;
