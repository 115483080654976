import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

const POIField = (props) => {
  const { id, value, label, icon, inline } = props;
  const {t} = useTranslation();

    if (!value || value === 'null') {
    return null;
  }

  const fieldContent = (
    <div className={(inline ? 'd-inline-block ' : '') + "mr-3 text-smaller clearfix fit-content"}>
      {icon
        ? (
          <i className={`icon ic-${icon} mr-2 text-primary text-bigger float-left`}/>
        )
        : null
      }
      <span>{ReactHtmlParser(value)}</span>
    </div>
  );

  if (label) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id={`tooltip-${id}`}>
            {t(`project:${id}`)}
          </Tooltip>
        }
      >
        {fieldContent}
      </OverlayTrigger>
    );
  }

  return fieldContent;
};

export default POIField;
