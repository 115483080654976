import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./config/locales/en/index";
import fr from "./config/locales/fr/index";

i18n.use(initReactI18next).init({
  fallbackLng: 'fr', 
  ns: ['common', 'poi', 'project'],
  resources: { en, fr },
});

export default i18n;
