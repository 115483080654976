import { use3DLayer } from "@mappr/react-lib";
import React from "react";
import { Button } from "react-bootstrap";

function ModelToggleButton() {
  const { visible, toggleVisibility } = use3DLayer();

  return (
    <Button
      active={visible}
      onClick={toggleVisibility}
      variant="primary"
      className="btn-square mb-0 ml-5 d-inline-block align-bottom"
    >
      3D
    </Button>
  );
}

export default ModelToggleButton;
