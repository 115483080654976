import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";

function FilterItem(props) {
  const {
    filterId,
    item,
    withIcon,
    onToggle,
    setShow,
    lang,
    selectedValues,
    filters,
    closeOnSelect = true,
  } = props;
  const itemId = item.value.toLowerCase().replace(/ /g, "-");
  const inputId = `filter-${filterId}-check-${itemId}`;
  // const icon = withIcon && (iconMap[item.value] || '');

  const filter = filters?.filter((filter) => filter.name === filterId).pop();
  const filterItem =
    filter &&
    filter?.stats
      ?.filter((filterItem) => filterItem.value === item.value)
      .pop();
  const isSelected = selectedValues?.[filterId]?.includes(item?.value) ?? false;
  const isDisabled = !filterItem && !isSelected;
  const count = filterItem
    ? filterItem.count
    : !filterItem && isSelected
    ? item.count
    : 0;

  const text = (lang === "fr" ? item.label_fr : item.label_en) || item.value;
  return (
    <Dropdown.Item
      disabled={isDisabled}
      onClick={() => {
        setShow(!closeOnSelect);
        onToggle(filterId, item.value);
      }}
      className={`list-group-item d-flex justify-content-between align-items-center ${
        !isDisabled ? "" : "opaque"
      }`}
    >
      <div className="custom-control custom-checkbox mr-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id={inputId}
          checked={isSelected}
          readOnly
        />
        <label className="custom-control-label" htmlFor={inputId}>
          {/*{icon && <i className={`icon text-normal line-height-1-5 align-middle mr-1 ${icon}`}/>}*/}
          {text}
        </label>
      </div>
      <span className="badge badge-primary badge-pill">{count}</span>
    </Dropdown.Item>
  );
}

FilterItem.propTypes = {
  filterId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    value: PropTypes.string,
    count: PropTypes.number,
  }).isRequired,
  isEnabled: PropTypes.bool,
  withIcon: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

FilterItem.defaultProps = {
  isChecked: false,
  isEnabled: true,
};

export default FilterItem;
