import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import ShareButtons from "../../../components/ShareButtons";
import { URLManager } from "@mappr/react-lib";

function POIHeader({
  isFullscreen,
  deselectBtnType,
  detachedHeader,
  history,
  deselected,
  toggleFullscreen,
  printPreviewRef,
  printable,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Navbar
        expand
        variant="light"
        fixed={detachedHeader ? "top" : "no"}
        style={detachedHeader ? {} : { zIndex: 1 }}
        className={`${
          !detachedHeader
            ? "position-absolute w-100 bg-transparent shadow-none"
            : "shadow-sm"
        } px-0 border-0`}
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 160 }}
          overlay={
            <Tooltip id={`tooltip-poi-close`}>
              {t("poiPage.popovers.close")}
            </Tooltip>
          }
        >
          <Button
            variant="link"
            className={`text-smaller font-weight-bold ${
              detachedHeader ? "" : "text-white"
            }`}
            onClick={() => {
              deselected();
              if (history) {
                history.goBack();
              }
            }}
          >
            {deselectBtnType === "back" ? (
              <>
                <i className="icon icon-back_arrow mr-2" />{" "}
                <span className="back-text">{t(`poiPage.back_to`)}</span>
              </>
            ) : (
              <i className="icon icon-close" />
            )}
          </Button>
        </OverlayTrigger>

        <Nav className="mr-auto" />
        {printable && printPreviewRef && (
          <ReactToPrint
            trigger={() => (
              <span>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 160 }}
                  overlay={
                    <Tooltip id={`tooltip-poi-print`}>
                      {t("poiPage.popovers.print")}
                    </Tooltip>
                  }
                >
                  <Button variant="link" className="text-smaller">
                    <i
                      className={`icon icon-print ${
                        detachedHeader ? "" : "text-white"
                      }`}
                    />
                  </Button>
                </OverlayTrigger>
              </span>
            )}
            content={() => printPreviewRef.current}
            bodyClass="printing-active"
          />
        )}
        <URLManager>
          {({ shortUrl, fullUrl }, { generateShortUrl }) => {
            return (
              <Dropdown className="">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 160 }}
                  overlay={
                    <Tooltip id={`tooltip-poi-share`}>
                      {t("poiPage.popovers.share")}
                    </Tooltip>
                  }
                >
                  <Dropdown.Toggle
                    variant="link"
                    className={`pl-3 py-0 no-chevron ${
                      detachedHeader ? "" : "text-white"
                    }`}
                  >
                    <span onClick={() => generateShortUrl()}>
                      <i className="icon icon-share" />
                    </span>
                  </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu alignRight={true} className="mt-3 py-0 pb-2">
                  <Dropdown.Header>{t(`poiPage.share`)}</Dropdown.Header>
                  <Dropdown.Item as="div" className="px-3 py-2">
                    <ShareButtons notooltip={true} shortUrl={shortUrl} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          }}
        </URLManager>
        {!detachedHeader && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 160 }}
            overlay={
              <Tooltip id={`tooltip-poi-enlarge`}>
                {t("poiPage.popovers.enlarge")}
              </Tooltip>
            }
          >
            <Button
              variant="link"
              className={`text-smaller ${detachedHeader ? "" : "text-white"}`}
              onClick={() => toggleFullscreen(!isFullscreen)}
            >
              <i
                className={`${
                  isFullscreen ? "icon-fullscreen_reverse" : "icon-fullscreen"
                } icon`}
              />
            </Button>
          </OverlayTrigger>
        )}
      </Navbar>
    </>
  );
}

POIHeader.propTypes = {
  deselected: PropTypes.func.isRequired,
  fixed: PropTypes.bool.isRequired,
  deselectBtnType: PropTypes.oneOf(["back", "cross"]),
  detachedHeader: PropTypes.bool.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  printPreviewRef: PropTypes.object,
  printable: PropTypes.bool,
};

POIHeader.defaultProps = {
  fixed: false,
  deselectBtnType: "back",
  detachedHeader: true,
};

export default POIHeader;
